import Header from '../../elements/Header/Header';
import Footer from '../../elements/Footer/Footer';

const Layout = ({ children }: any) => {
  return (
    <>
      <Header />
        <main className="main">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
