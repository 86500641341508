import './Schedule.scss';

type ScheduleProps = {
  className?: string;
  animation: string;
  day: string;
  items: string[];
};

const Schedule = ({ className, animation, day, items } : ScheduleProps) => {
  return (
    <div className={`schedule ${className}`} data-aos={animation} data-aos-duration="750" data-aos-once="true">
      <div className="schedule__inner">
        <h3 className="schedule__title">{day}</h3>
        {items.map((item, index) => (
          <p className="schedule__item" key={index}>{item}</p>
        ))}
      </div>
    </div>
  );
}

export default Schedule;
