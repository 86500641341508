import { Switch } from 'react-router';
import { Route } from 'react-router-dom';
import Layout from './components/layouts/Layout/Layout';
import Home from './pages/Home';
import PageNotFound from './pages/PageNotFound';
import PrivacyPolicy from './pages/PrivacyPolicy';

const App = () => {
  return (
    <Layout>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </Layout>
  );
}

export default App;
