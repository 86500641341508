const PrivacyPolicy = () => {
  return (
    <section className="section section--white-bg">
      <div className="container">
        <h1 className="title is-3 has-text-centered">Privacy Policy</h1>
        <div className="columns">
          <div className="column is-12">
            <div className="content">
            <p>GDPR states that personal data should be ‘processed fairly & lawfully’ and ‘collected for specified, explicit and legitimate purposes’ and that individuals data is not processed without their knowledge and only with their ‘explicit’ consent. GDPR covers personal data relating to individuals. JS Dance Academy is committed to protecting the rights and freedoms of individuals with respect to the processing of all personal data.</p>
            <p>Details from completed registration forms are transferred to a spreadsheet on a password protected computer (only used by JS Dance Academy) and will not be used for any other purpose outside of the academy, or disclosed to any outside parties. Participants paper registration forms are kept in a folder either with the Principal or at the Principals home.</p>
            <p>Email addresses will be used to contact participants about all academy business. 
            Texts/What’sApp to mobiles may be used to cancel classes.</p>
            <p>Medical/emergency contact details are required in case of issues with participants whilst at class.</p>
            <p>Paper forms will be shredded/spreadsheet entries deleted, after 6 months of non-attendance (apart from accident/injury reports) unless individuals request to remain on the database.</p>
            <p>You have the right to see any data held about you. You also have the right to correct any wrong details and opt out of receiving emails and texts from JS Dance Academy. If you wish to opt out of receiving emails and texts please talk to the Principal.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicy;