import { NavLink } from 'react-router-dom';
import './Footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-8">
            <p>
              If you are interested in taking any classes please don't hesitate to get in touch.<br/>
              Either phone or email for more information.
            </p>
          </div>
          <div className="column is-4">
            <p className="has-text-right-tablet has-text-centered-mobile">
              <a href="mailto:info@jsdanceacademy.co.uk">info@jsdanceacademy.co.uk</a><br/>
              <a href="tel:01462612125">01462 612125</a>
            </p>
          </div>
          <div className="column is-8">
            <p className="has-text-centered-mobile">
              <NavLink to="/" className="footer__link">Home</NavLink>
              <NavLink to="/privacy-policy" className="footer__link">Privacy Policy</NavLink>
            </p>
          </div>
          <div className="column is-4">
            <p className="has-text-right">Copyright &copy; 2024 JS Dance Academy</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
