import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router } from 'react-router-dom';
import 'bulma/css/bulma.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './scss/core.scss';
import App from './App';
import history from './tools/history';

AOS.init();

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <Route component={App} />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
