import './Navbar.scss';
import { NavLink } from 'react-router-dom';
import Logo from '../../../assets/logo.png';

const Navbar = () => {

  return (
    <div className="navbar" role="navigation">
      <div className="container">
        <div className="navbar-brand">
          <NavLink className="navbar-item" to="/">
            <img src={Logo} width="150" alt="JS Dance Academy" />
            <span>JS Dance Academy</span>
          </NavLink>
        </div>
        <div className="navbar-menu">
          <div className="navbar-end">
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
