import './Hero.scss';

const Hero = () => {
  return (
    <section className="hero is-fullheight-with-navbar">
      <video className="hero__video" src="/assets/videos/Bokeh.mp4" autoPlay muted loop playsInline></video>
      <div className="hero-body">
        <div className="container">
          <h1
            className="hero__title"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="750"
          >
            Welcome to<br/> JS Dance Academy
          </h1>
          <h2
            className="hero__subtitle"
            data-aos="fade-up"
            data-aos-delay="250"
            data-aos-duration="750"
          >
            We aim to promote dancing and encourage adults of all ages and abilities to reach their full potential.
          </h2>
        </div>
      </div>
    </section>
  );
}

export default Hero;