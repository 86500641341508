import Navbar from '../../elements/Navbar/Navbar';
import './Header.scss';

const Header = () => {
  return (
    <div className="header">
      <Navbar />
    </div>
  );
};

export default Header;
