import Hero from "src/components/elements/Hero/Hero";
import Schedule from "src/components/elements/Schedule/Schedule";

const Home = () => {
  return (
    <>
      <Hero />
      <section className="section section--white-bg">
        <div className="container">
          <div className="schedule-wrapper">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="has-text-centered">
                  <h3 className="emphasis">
                    Autumn Term<br/>
                    Monday 4th November - Wednesday 11th December<br/>
                  </h3>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-6">
                <Schedule
                  day="Monday"
                  items={['7pm Westend Workout', '7.55pm Fitsteps', 'Followed by Fitsteps Tone']}
                  animation="fade-right"
                />
              </div>
            </div>
            <div className="columns">
              <div className="column is-offset-6 is-6">
                <Schedule
                  className="schedule--right"
                  day="Tuesday"
                  items={['7pm Elementary Tap', '7.55pm Intermediate Tap', 'Followed by Advance Tap']}
                  animation="fade-left"
                />
              </div>
            </div>
            <div className="columns">
              <div className="column is-6">
                <Schedule
                  day="Wednesday"
                  className="schedule--last"
                  items={['7.45pm Mixed Level Ballet', 'Followed by Pointe at 8.45pm']}
                  animation="fade-right"
                />
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column is-12">
              <h4 className="title is-4">Prices</h4>
              <p>
                All main classes are £6 and its a 6 week half term so £36.<br/>
                All 'add-on' classes are £3.30 so for 6 weeks it's £19.80.
              </p>
              <p>Sadly I cannot offer any PAYG classes any more.</p>
            </div>
          </div>
          <div className="columns is-multiline">
            <div className="column is-12">
              <h4 className="title is-4">Finding us and getting in touch</h4>
              <p>
                <strong>Mondays &amp; Wednesdays</strong><br/>
                Our Lady School<br/>
                Old Hale Way<br/>
                Hitchin<br/>
                SG5 1XT
              </p>
              <br/>
              <p>
                <strong>Tuesdays</strong><br/>
                Holwell Village Hall<br/>Pirton Rd<br/>Holwell<br/>
                Hitchin<br/>
                SG5 3SS
              </p>
              <br/>
              <p>
                Email - <a href="mailto:info@jsdanceacademy.co.uk">info@jsdanceacademy.co.uk</a><br/>
                Facebook - <a href="https://www.facebook.com/JS-Dance-Academy-223523381032449" target="_blank" rel="noreferrer">JS Dance Academy</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;